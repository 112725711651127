import React, { FC } from 'react';
import Navbar from './navbar';
import Footer from './footer';

interface Props {
  children?: any;
}

const Layout: FC<Props> = ({ children }) => (
  <div>
    <header>
      <Navbar />
    </header>
    <main>{children}</main>
    <footer>
      <Footer />
    </footer>
  </div>
);

export default Layout;
