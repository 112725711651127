/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import GithubIcon from '../content/assets/github.svg';
import LinkedInIcon from '../content/assets/linked-in.svg';
import NavList, { NavListItem } from './nav-list';

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 55, height: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          title
        }
      }
    }
  `);

  const { author, title } = data.site.siteMetadata;
  return (
    <nav
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'var(--green)',
        justifyContent: 'space-between',
      }}
    >
      <NavList>
        <NavListItem>
          <Image
            fixed={data.avatar.childImageSharp.fixed}
            alt={author}
            style={{
              margin: '.5rem',
              borderRadius: '100%',
            }}
            imgStyle={{
              borderRadius: '50%',
            }}
          />
        </NavListItem>
        <NavListItem>
          <Link
            style={{
              color: 'var(--white)',
              minWidth: '1.5rem',
              maxWidth: '1.5rem',
              margin: '.5rem',
              whiteSpace: 'nowrap',
            }}
            to="/"
          >
            {title}
          </Link>
        </NavListItem>
      </NavList>

      <div style={{ display: 'var(--collapse-display)' }}>
        <NavList>
          <NavListItem>
            <Link
              style={{
                color: 'var(--white)',
                minWidth: '1.5rem',
                maxWidth: '1.5rem',
                margin: '.5rem',
              }}
              to="/blog/about-me"
            >
              About
            </Link>
          </NavListItem>
          <NavListItem>
            <Link
              style={{
                color: 'var(--white)',
                minWidth: '1.5rem',
                maxWidth: '1.5rem',
                margin: '.5rem',
              }}
              to="/blog/resources"
            >
              Resources
            </Link>
          </NavListItem>
          <NavListItem>
            <a href="https://github.com/jmptr">
              <img
                src={GithubIcon}
                alt="Github"
                style={{
                  minWidth: '1.5rem',
                  maxWidth: '1.5rem',
                  margin: '.5rem',
                }}
              />
            </a>
          </NavListItem>
          <NavListItem>
            <a href="https://www.linkedin.com/in/jmpresley/">
              <img
                src={LinkedInIcon}
                alt="LinkedIn"
                style={{
                  minWidth: '1.5rem',
                  maxWidth: '1.5rem',
                  margin: '.5rem',
                }}
              />
            </a>
          </NavListItem>
        </NavList>
      </div>
    </nav>
  );
};

export default Navbar;
