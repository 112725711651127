import React from 'react';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <nav
      style={{
        backgroundColor: 'var(--green)',
        display: 'flex',
        color: 'var(--white)',
        alignItems: 'stretch',
        padding: '2rem',
        justifyContent: 'space-between',
      }}
    >
      <ul
        style={{
          listStyleType: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        <li>{`© Jon "JM" Presley ${year}`}</li>
      </ul>

      <ul
        style={{
          listStyleType: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        <li>
          Built with{' '}
          <a
            style={{
              color: 'var(--white)',
            }}
            href="https://www.gatsbyjs.org"
          >
            Gatsby
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Footer;
