import React, { FC } from 'react';

export const NavListItem: FC<{}> = props => {
  const { children } = props;
  return <li>{children}</li>;
};

const NavList: FC<{}> = props => {
  const { children } = props;
  return (
    <ul
      style={{
        display: 'flex',
        alignItems: 'center',
        listStyleType: 'none',
        margin: 0,
        padding: 0,
      }}
    >
      {children}
    </ul>
  );
};

export default NavList;
